import React from 'react';
import Layout from '../components/layout';
import { Card, Row, Col, Avatar, Badge, Tag, Button } from 'antd';
import { Link } from 'gatsby';
import { EnvironmentOutlined, BankOutlined, KeyOutlined, ThunderboltOutlined, SyncOutlined, CheckOutlined, UserOutlined, TeamOutlined, OrderedListOutlined, MessageOutlined, BellOutlined, FileProtectOutlined, CloseOutlined, CompassOutlined } from '@ant-design/icons';
import SEO from '../components/seo';
import heroSquare from '../assets/hero-square.svg';
import apartmentPhoto from '../assets/blueorangeapt.jpg';
import {Fade} from 'react-reveal';


const Features = (props) => {
    return (
      <Layout {...props}>
        <SEO title="Features" description='RealtyStack Features' />
        <div style={{height:'100%', paddingTop:'110px', background:'#fff'}}>
        <Fade left> 
            <div
            style={{position:'absolute', left: 60, bottom: 100}}
            >
                <div style={{position:'absolute', height:'250px', width:'250px', backgroundColor:'#f5f5f5', left: 50, top: -50 }}/>
                <img
                src={heroSquare}
                height={250}
                style={{position:'relative'}}
                />
            </div>
        </Fade> 
          <Row justify='center'>
            <Col xs={22} sm={22} md={18} lg={16}>
                <div id='payments' style={{textAlign:'center'}}>
                    <p style={{textTransform:'uppercase', letterSpacing:'0.025em', fontWeight:600, fontSize:'1rem', color:'#43c463'}}>Payments</p>
                    <h2 style={{fontWeight:800}}>A better way to manage your portfolio</h2>
                    <p style={{color:'#6b7280', fontSize:'1.2rem'}}>RealtyStack manages all of the simple things for you so you can focus on what you do best</p>
                </div>
                <Row>
                    <Col md={24} lg={12}>
                        <Card
                        bordered={false}
                        >
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<BankOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Major Payment providers"
                            description="Our payment providers enable access to all major bank accounts and credit cards for sending and recieving funds"
                            />
                        </Card>
                    </Col>
                    <Col md={24} lg={12}>
                        <Card
                        bordered={false}
                        >
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<ThunderboltOutlined />} style={{backgroundColor:'#43c463'}}/>}
                            title="Fast Payments"
                            description="Get your rent payments faster"
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={12}>
                        <Card
                        bordered={false}
                        >
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<KeyOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Secure transactions"
                            description="All transactions are safe and secure"
                            />
                        </Card>
                    </Col>
                    <Col md={24} lg={12}>
                        <Card
                        bordered={false}
                        >
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<SyncOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Automated processing"
                            description="Setup a lease once and let us handle the rest"
                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
          </Row>
          <Row justify='center' style={{paddingTop:'200px'}}>
            <Fade right> 
            <div
            style={{position:'absolute', right:60}}
            >
                <div style={{position:'absolute', height:'250px', width:'250px', backgroundColor:'#f5f5f5', left: -50, top: 50 }}/>
                <img
                src={heroSquare}
                height={250}
                style={{position:'relative'}}
                />
            </div>
            </Fade>
            <Col xs={22} sm={22} md={18} lg={16}>
                <Row>
                    <Col md={24} lg={12} style={{padding:'15px'}}>
                        <div id='management'>
                            <p style={{textTransform:'uppercase', letterSpacing:'0.025em', fontWeight:600, fontSize:'1rem', color:'#43c463'}}>Management</p>
                            <h2 style={{fontWeight:800}}>Manage properties</h2>
                            <p style={{color:'#6b7280', fontSize:'1.2rem'}}>RealtyStack manages all of the simple things for you so you can focus on what you do best</p>
                        </div>
                        <Card
                        bordered={false}
                        bodyStyle={{paddingLeft:'0px'}}
                        >   
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<CompassOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Track Maintenance"
                            description="Handle both internal and external maintenance requests for your properties"
                            />
                        </Card>
                        <Card
                        bordered={false}
                        bodyStyle={{paddingLeft:'0px'}}
                        >   
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<OrderedListOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Todos"
                            description="Maintain a todo list of everything needed to handle for your portfolio"
                            />
                        </Card>
                        <Card
                        bordered={false}
                        bodyStyle={{paddingLeft:'0px'}}
                        >   
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<TeamOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Create Leases"
                            description="Seemlessly build and start leases for your new and existing residents"
                            />
                        </Card>
                    </Col>
                    <Col md={24} lg={12} style={{padding:'15px', paddingTop:'80px'}}>
                    <Card
                        hoverable
                        style={{borderRadius:'4px'}}
                        bordered={true}
                        cover={
                            <div>
                                <img alt="Property" style={{width: '100%', height:'200px', objectFit:'cover'}} src={apartmentPhoto} /> 
                                <div style={{
                                    position:'absolute',
                                    height:'20px',
                                    width: 'auto',
                                    left: 0, 
                                    top: 15, 
                                    padding: '1px 8px', 
                                    color: '#fff', 
                                    background: '#52c41a',
                                    fontSize: '12px'
                                }}>
                                    <span>Occupied</span>
                                </div>
                            </div>
                        }
                        >
                            <Card.Meta
                                title={<div>
                                    <Link style={{color:'inherit', fontWeight:400}}>Robin Complex</Link>
                                    <Tag 
                                    style={{
                                    marginLeft:'15px', 
                                    borderRadius:'10px', 
                                    fontSize:'11px',
                                    backgroundColor:'#e6f7ff',
                                    color:'#1890ff',
                                    border:'#91d5ff',
                                    verticalAlign: 'middle' 
                                    }}
                                    >
                                        2 Units
                                    </Tag>
                                </div>}
                                description={[
                                <div style={{display:'inline-flex', fontWeight:300, color:'#495057'}}>
                                    <EnvironmentOutlined style={{marginTop:'12px', marginRight:'10px'}} />
                                    <div>
                                        <p style={{marginBottom:'0px'}}>69 Robin Rd</p>
                                        <p>Hartford, CT 06118</p>
                                    </div>
                                </div>
                                ]} 
                            />
                            <p style={{marginBottom:'0px'}}>
                                <span style={{fontWeight:600}}>Multi-family</span>
                                <span style={{ float:'right'}}>
                                <Badge 
                                status={'success'} 
                                style={{paddingLeft:'5px'}}/>
                                <span style={{color:'#43c463'}}>active</span>
                                </span>
                            </p>
                            <p className='ant-card-meta-description' style={{marginBottom:'0px', marginTop:'2px'}}>
                                {/* {item.property_description} */}
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Col>
          </Row>
          <Row justify='center' style={{paddingTop:'200px'}}>
          <Fade left> 
            <div
            style={{position:'absolute', left:60}}
            >
                <div style={{position:'absolute', height:'250px', width:'250px', backgroundColor:'#f5f5f5', left: 50, top: 50 }}/>
                <img
                src={heroSquare}
                height={250}
                style={{position:'relative'}}
                />
            </div>
            </Fade>
            <Col xs={22} sm={22} md={18} lg={16}>
                <Row>
                    <Col md={24} lg={12} style={{padding:'15px', paddingTop:'80px'}}>
                        <Card
                        bordered={true}
                        hoverable
                        >   
                            <Card.Meta
                            avatar={<Avatar size="large" icon={<UserOutlined />} style={{backgroundColor:'#f56a00'}}/>}
                            title={
                                <div style={{display: 'inline-flex'}}>
                                    Jake Casper
                                <Tag
                                style={{
                                marginLeft:'15px', 
                                borderRadius:'10px', 
                                fontSize:'11px',
                                color:'#52c41a', //ffa39e
                                backgroundColor:'#f6ffed', //ffa39e
                                border: 'none'
                                }}
                                >
                                <CheckOutlined style={{color:'#52c41a'}} /> Lease
                                </Tag>
                          </div>
                            }
                            description="jake.caspert@gmail.com"
                            />
                        </Card>
                        <Card
                        bordered={true}
                        hoverable
                        >   
                            <Card.Meta
                            avatar={<Avatar size="large" icon={<UserOutlined />} style={{backgroundColor:'#7265e6'}}/>}
                            title={
                                <div style={{display: 'inline-flex'}}>
                                    Anna Struple
                                <Tag
                                style={{
                                marginLeft:'15px', 
                                borderRadius:'10px', 
                                fontSize:'11px',
                                color:'#f5222d', //ffa39e
                                backgroundColor:'#fff1f0', //ffa39e
                                border: 'none'
                                }}
                                >
                                <CloseOutlined style={{color:'#f5222d'}} /> Lease
                                </Tag>
                          </div>
                            }
                            description="a.struple@gmail.com"
                            />
                        </Card>
                        <Card
                        bordered={true}
                        hoverable
                        >   
                            <Card.Meta
                            avatar={<Avatar size="large" icon={<UserOutlined />} style={{backgroundColor:'#00a2ae'}}/>}
                            title={
                                <div style={{display: 'inline-flex'}}>
                                    Howard Tranf
                                <Tag
                                style={{
                                marginLeft:'15px', 
                                borderRadius:'10px', 
                                fontSize:'11px',
                                color:'#f5222d', //ffa39e
                                backgroundColor:'#fff1f0', //ffa39e
                                border: 'none'
                                }}
                                >
                                <CloseOutlined style={{color:'#f5222d'}} /> Lease
                                </Tag>
                          </div>
                            }
                            description="h.tranf@gmail.com"
                            />
                        </Card>
                        <Card
                        bordered={true}
                        hoverable
                        >   
                            <Card.Meta
                            avatar={<Avatar size="large" icon={<UserOutlined />} style={{backgroundColor:'#ffbf00'}}/>}
                            title={
                                <div style={{display: 'inline-flex'}}>
                                    Adrian Stefano
                                <Tag
                                style={{
                                marginLeft:'15px', 
                                borderRadius:'10px', 
                                fontSize:'11px',
                                color:'#52c41a', //ffa39e
                                backgroundColor:'#f6ffed', //ffa39e
                                border: 'none'
                                }}
                                >
                                <CheckOutlined style={{color:'#52c41a'}} /> Lease
                                </Tag>
                          </div>
                            }
                            description="adrian.stefanoo@gmail.com"
                            />
                        </Card>
                    </Col>
                    <Col md={24} lg={12} style={{padding:'15px'}}>
                        <div style={{paddingLeft:'20px'}} id='residents'>
                            <p style={{textTransform:'uppercase', letterSpacing:'0.025em', fontWeight:600, fontSize:'1rem', color:'#43c463'}}>Residents</p>
                            <h2 style={{fontWeight:800}}>Manage residents</h2>
                            <p style={{color:'#6b7280', fontSize:'1.2rem'}}>RealtyStack manages all of the simple things for you so you can focus on what you do best</p>
                        </div>
                        <Card
                        bordered={false}
                        >   
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<MessageOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Communication"
                            description="Respond and communicate with residents through the platform"
                            />
                        </Card>
                        <Card
                        bordered={false}
                        >   
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<FileProtectOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Leases"
                            description="Simply add residents to a lease and begin handling payments"
                            />
                        </Card>
                        <Card
                        bordered={false}
                        >   
                            <Card.Meta
                            avatar={<Avatar shape="square" size="large" icon={<BellOutlined />} style={{backgroundColor:'#43c463'}} />}
                            title="Reports"
                            description="Get updated the moment your residents pay rent, request maintenance, and more"
                            />
                        </Card>
                    </Col>
                    <div
                        style={{
                        backgroundImage: `url("${heroSquare}; viewBox="0 0 350 350"")`,
                        height:'50px',
                        width:'50px',
                    }}
                    />
                </Row>
            </Col>
          </Row>
          <div style={{height:'300px', background:'linear-gradient(to bottom right,#005cb9,rgb(0, 105, 255))', textAlign:'center', verticalAlign:'middle', paddingTop:'100px', marginTop:'100px'}}>
                <h2 style={{fontSize:'36px', fontWeight:'400', letterSpacing:'-.9px', color:'#fff', marginBottom:'5px'}}>Ready to dive in?</h2>
                <h2 style={{fontSize:'30px', fontWeight:'400', letterSpacing:'-.9px', color:'#fff'}}>Get started with a free account today</h2>
                <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/signup/`}>
                <Button size='large' type='primary' style={{ background:'rgb(67, 196, 99)', border:'none'}}>
                    Create an account
                </Button>
                </a>
            </div>
        </div>
      </Layout>
    )
}

export default Features;
